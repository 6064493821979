



































































import { defineComponent } from "@vue/composition-api";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import { isNasabah } from "@/apps/accounts/modules/store";
import { listVM } from "../../pengelola/modules/store";
import { ContextMenu, Menu } from "@/apps/core/models/contextMenu";

export default defineComponent({
  name: "AnggotaList",
  components: {
    AnggotaListBox: () =>
      import("@/apps/pengelola/components/AnggotaListBox.vue"),
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
    BasicSelect: () =>
      import("@/apps/core/components/BasicSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const composition = useInfiniteScrollList(listVM);
    const contextMenu = new ContextMenu([
      new Menu("Tambah Nasabah", "nasabah-add"),
      new Menu("Tambah Pengelola", "pengelola-add"),
    ]);
    return {
      // Data
      contextMenu,

      // Computed
      isNasabah,

      // Composition
      ...composition,
    };
  },
});
